'use client';

/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */

import { createContext, useContext, useMemo, useState, useCallback, useEffect } from 'react';
import { usePathname, useRouter } from 'next/navigation';
import QueryString from 'qs';
import { useSnackbar } from 'notistack';
import { AnimatePresence } from 'framer-motion';
import moment from 'moment-timezone';
import { Loading } from '../loading';
import { axiosInstance } from '@/app/utilities/helpers/axios';
import { decryptResponse, encryptRequest } from '@/app/utilities/helpers/security';
import { paths } from '@/app/utilities/consts';

export const UserContext = createContext({
  user: {},
  timezone: 'Asia/Shanghai',
  setUser: () => {},
  getUser: () => {},
  setLoading: () => {},
});

export function useUserContext() {
  return useContext(UserContext);
}

export function UserProvider({ children }) {
  const [user, setUser] = useState({});
  const router = useRouter();
  const pathname = usePathname();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(true);
  const localTimeZone = moment.tz.guess();

  const getUser = useCallback(async () => {
    try {
      const body = {
        data: encryptRequest({}),
      };

      const response = await axiosInstance.post(
        'UsD0YG9TJloNQNXryoiHndBuWa7zKDWs',
        QueryString.stringify(body),
        {
          withCredentials: true,
        }
      );

      const responseData = decryptResponse(response.data);
      if (responseData.success) {
        return responseData.admin;
      }
      return null;
    } catch (e) {
      return null;
    }
  }, []);

  const userLogin = useCallback(async () => {
    const user = await getUser();

    if (!user) {
      enqueueSnackbar('Please login again', { variant: 'error' });
      router.push('/login');
    } else if (user?.type && user.type === 'reseller') {
      if (
        pathname === paths.orders ||
        pathname === paths.appSettings ||
        pathname === paths.languageSettings ||
        pathname === paths.promotion
      ) {
        router.push('/404');
      } else {
        setUser(user);
        setLoading(false);
      }
    } else if (user?.type && user.type === 'admin') {
      const regex = /^\/devices\/.+$/;

      if (
        pathname === paths.resellers ||
        pathname === paths.credits ||
        pathname === paths.languageSettings ||
        regex.test(pathname) ||
        pathname === paths.appSettings ||
        pathname === paths.promotion
      ) {
        router.push('/404');
      } else {
        setUser(user);
        setLoading(false);
      }
    } else {
      setUser(user);
      setLoading(false);
    }
  }, [enqueueSnackbar, getUser, pathname, router]);

  useEffect(() => {
    if (pathname === '/login' || pathname === '/404') {
      setLoading(false);
      return;
    }

    userLogin();
  }, [pathname, router, userLogin]);

  const value = useMemo(
    () => ({
      user,
      timezone: localTimeZone,
      setUser,
      setLoading,
      getUser,
    }),
    [getUser, localTimeZone, user]
  );

  return (
    <AnimatePresence>
      {loading ? (
        <Loading />
      ) : (
        <UserContext.Provider value={value}>{children}</UserContext.Provider>
      )}
    </AnimatePresence>
  );
}
